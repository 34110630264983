import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import axios from 'axios';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import "flag-icons/css/flag-icons.min.css";
import './leads.css';
import MultiSelectCategories from './multiselectcategories';
import ShareObject from './sharejob';

import SplitPane from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';

const defaultColDef = {
    filter: true,
    sortable: true,
    resizable: true,
    enableRowGroup: true
};
const autoGroupColumnDef = {
  pinned: 'left', // Pin the group column to the left
}

const defaultSideBar = {
  toolPanels: [
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      toolPanelParams: {
        suppressExpandAll: true,
        suppressFilterSearch: true,
      }
    },
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'column',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressExpandAll: true,
        suppressFilterSearch: true,
      },
    },
  ],
};
const rowSelection = 'single';
const rowGroupPanelShow = 'onlyWhenGrouping';

const rowModelType = 'serverSide';
const cacheBlockSize = 100;

const pastelBackgrounds = ["#FFEBEE","#F5FFFA","#FFF0F5","#FFDAB9","#F0F8FF","#FFFACD","#FFE4E1","#F0FFF0","#FFF5EE","#E0FFFF"];

class UserCategories extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isGridView: false,
            gridApi: null,
            rowData: [],
            columnDefs: [],
            selectedData: [],
            selectedId: 0,
            updatingRowData: false,
            gsplit: [`0%`, 'auto'],
            cards: [],
            selectedCard: 0,
            lastVisibleCard: 0,
            filterMode: 0,
            isCategoryModalOpen: false,
            isCategoryModalNew: false,
            isShareModalOpen : false,
          }

        this.tableRefs = [];
        this.onGridReady = this.onGridReady.bind(this);
        this.getRows = this.getRows.bind(this);
        this.getChildCount = this.getChildCount.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.onModelUpdated = this.onModelUpdated.bind(this);
        this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
        this.groupCountComparator = this.groupCountComparator.bind(this);
        this.onStoreRefreshed = this.onStoreRefreshed.bind(this);
        this.checkRenderer = this.checkRenderer.bind(this);
        this.statusRenderer = this.statusRenderer.bind(this);
        this.flagRenderer = this.flagRenderer.bind(this);
        this.tldRenderer = this.tldRenderer.bind(this);
        this.urlRenderer = this.urlRenderer.bind(this);
        this.toggleView = this.toggleView.bind(this);
        this.refreshCards = this.refreshCards.bind(this);
        this.cardSelectionChanged = this.cardSelectionChanged.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.handleCardsScroll = this.handleCardsScroll.bind(this);
        this.setCategoryModalNew = this.setCategoryModalNew.bind(this);
        this.openCategoryModal = this.openCategoryModal.bind(this);
        this.closeCategoryModal = this.closeCategoryModal.bind(this);
        this.editUCG = this.editUCG.bind(this);
        this.deleteUCG = this.deleteUCG.bind(this);
        this.copyTable = this.copyTable.bind(this);
        this.agRequestRef = React.createRef();
        this.openShareModal = this.openShareModal.bind(this);
        this.closeShareModal = this.closeShareModal.bind(this);
    };
 
    handleCardsScroll(e) {
      const cardsList = e.currentTarget;
      const top = (cardsList.scrollTop === 0);
      const bottom = (Math.abs(cardsList.scrollHeight - (cardsList.scrollTop + cardsList.clientHeight)) <= 5);
      if (top) {
        // this.state.gridApi?.ensureIndexVisible(0, 'top')
      } else if (bottom) {
        const index = this.state.cards.length;
        this.state.gridApi?.ensureIndexVisible(index, 'bottom');
        const lastVisibleIndex = Math.max(0, index - 1);
        const lastVisibleCard = this.state.cards[lastVisibleIndex].id;
        this.setState({ lastVisibleCard: lastVisibleCard });
      } else {
        this.setState({ lastVisibleCard: 0 });
      }
    }

    checkRenderer (params) {
      return (<div className='d-flex justify-content-center'><i className={`${(params.value && params.value === 1) ? 'bi bi-file-check' : ''}`}></i></div>)
    };

    flagRenderer (params) {
      return ((params.value && params.value !== '') ? <div title={params.data ? params.data.countryname : ''} className={`fi fi-${params.value.toLowerCase()}`}></div> : '')
    }

    tldRenderer (params) {
      return ((params.value && params.value !== '') ? 
          <a rel="noreferrer" target='_blank' href={`https://${params.value}`}>{params.value}</a>
        : '' )
    }

    urlRenderer (params) {
      return ((params.value && params.value !== '') ? (
          <div className='d-flex justify-content-center mx-1'>
            <a rel="noreferrer" target='_blank' href={`${params.value}`}><i title={params.title} className={`${params.icon}`}></i></a>
          </div> 
        ) : '' )
    }

    statusRenderer (params) {
      return (<div className='mx-auto' style={{width: '18px', height:'18px'}}><i title={`Site Scan ${(params.value === 1) ? 'Completed' : 'Pending'}`} className={`bi ${(params.value === 1) ? 'bi-check-circle-fill' : 'bi-circle-half'} text-dark`}></i></div>)
    }

    fetchData = async() => {
      if (!this.state.updatingRowData) {
        this.setState({ updatingRowData: true });
        this.props.uucgd(true);

          // this.state.gridApi?.setServerSideDatasource({getRows: this.getRows});
          this.state.gridApi?.refreshServerSide({purge: false});
          
          // var rowCount = this.state.gridApi?.getDisplayedRowCount();
      }
    }

    // Custom comparator function to sort groups by group counts
    groupCountComparator(valueA, valueB, nodeA, nodeB, isInverted) {
      // Get the group counts for the two groups
      const countA = nodeA ? nodeA.allChildrenCount : valueA; // Number of children in group A
      const countB = nodeB ? nodeB.allChildrenCount : valueB; // Number of children in group B

      // Compare the counts and return the result
      if (countA < countB) {
        return -1;
      } else if (countA > countB) {
        return 1;
      } else {
        return 0;
      }
    }

    onGridReady(params) {
        this.setState({
            gridApi: params.api,
        })
        params.api.setServerSideDatasource({getRows: this.getRows});
      }

    getRows(params) {
      const serverURL = `/api/getagusercategorygroups/${this.props.uid}`;
      this.agRequestRef.current = params.request;
      
      this.setState({ updatingRowData: true });
      this.props.uucgd(true);
      fetch(serverURL, {
        method: 'post',
        body: JSON.stringify(params.request),
        headers: { "Content-Type": "application/json; charset=utf-8" }
      })
      .then(httpResponse => httpResponse.json())
      .then(response => {
        params.successCallback(response.rows, response.lastRow);
        this.setState({ updatingRowData: false });
        this.props.uucgd(false);
      })
      .catch(error => {
        console.error(error);
        params.failCallback();
      })
    }

    getChildCount(data) {
      return data ? data.childCount : undefined
    }
    
    onSelectionChanged() {
      if (this.state.isGridView) {
        const data = this.state.gridApi.getSelectedRows();
        this.setState({
            selectedData: data,
            selectedId: data.id
        })
        this.props.sucgu(data.id);

        const index = Math.max(this.state.cards.findIndex(row => (row.id === data.id)), 0);
        this.cardSelectionChanged(index);
      }
    }

    onModelUpdated = (params) => {
      this.setState({ updatingRowData: false });
      this.props.uucgd(false);
      this.refreshCards();
    }

    onStoreRefreshed = (params) => {
      this.setState({ updatingRowData: false });
      this.props.uucgd(false);
    }

    onFirstDataRendered = (params) => {
      var cols = [
        { field: 'id', headerName: 'Id', filter: 'agNumberColumnFilter', cellStyle: {textAlign: 'center'}, headerClass: 'custom-header-class' },
        { field: 'name', headerName: 'Category Group', filter: 'agTextColumnFilter', width: 150, comparator: this.groupCountComparator, headerClass: 'custom-header-class'},
        { field: 'catcount', headerName: 'Category Count', filter: 'agNumberColumnFilter', cellStyle: {textAlign: 'center'}, headerClass: 'custom-header-class' },
        { field: 'categoryies', headerName: 'Categories', hide: true, filter: 'agTextColumnFilter',  headerClass: 'custom-header-class'},
      ];
      this.setState({columnDefs: cols})
    }

    toggleView(currentView) {
      this.setState({
        isGridView: currentView,
        gsplit: currentView ? ["100%", 'auto'] : ["0%", 'auto']
      })
      this.refreshCards();
    }

    refreshCards() {
      var cards = [];
      this.state.gridApi && this.state.gridApi.forEachNode((node, index) => {if ((!node.group) && (node.data)) cards.push(node.data)});
      if (cards.length > 0) {
        this.setState({cards: cards});
      }
      
      if (cards.length > 0) { 
        const selCard = Math.max(cards.findIndex(row => (row.id === this.state.selectedId)), 0);
        const selData = cards[selCard];
        this.setState({
          selectedCard: selCard,
          selectedData: selData,
          selectedId: selData.id
        });
        this.props.sucgu(selData.id);
      }

      setTimeout(() => {
        const card = document.getElementById(`lead-${this.state.lastVisibleCard}`);
        card?.scrollIntoView(false);
      }, 50)
    }
    
    cardSelectionChanged(rowid) {
      const data = this.state.cards[rowid];
      this.setState({
        selectedData: data,
        selectedId: data.id,
        selectedCard: rowid,
        lastVisibleCard: rowid,
      })
      this.props.sucgu(data.id);
    }

    applyFilter(filterValues) {
      this.state.gridApi && this.state.gridApi.setFilterModel(filterValues);
      if (!this.state.isGridView) this.refreshCards();
    }

    setCategoryModalNew(isNew) {
      this.setState({ isCategoryModalNew: isNew })
    }

    openCategoryModal() {
      this.setState({ isCategoryModalOpen: true })
    }

    closeCategoryModal() {
      this.setState({ isCategoryModalOpen: false })
    }

    editUCG(e) {
      const index = e.currentTarget.dataset.id;
      this.cardSelectionChanged(index);
      this.setCategoryModalNew(false);

      this.openCategoryModal();
    }

    deleteUCG(e) {
      const index = e.currentTarget.dataset.id;
      const data = this.state.cards[index];

      const request = {
        url: '/deleteUserGroup',
        method: 'POST',
        data: {ucgId: data.id},
      }

      axios(request)
      .then((res) => {
          console.log(res);
      })
      .catch((err) => {
          console.log(err)
      })
      this.props.pfr();
    }

    async copyTable(e) {
      const index = e.currentTarget.dataset.id;
      if (this.tableRefs[index]) {
        const tableText = this.tableRefs[index].current.outerText;
        const tableHtml = this.tableRefs[index].current.outerHTML;

        try {
          await navigator.clipboard.write([
            new ClipboardItem({
              'text/html': new Blob([tableHtml], { type: 'text/html' }),
              'text/plain': new Blob([tableText], { type: 'text/plain' }),
            }),
          ]);
          console.log('Table copied to clipboard!');
        } catch (error) {
          console.error('Failed to copy table: ', error);
        }
      }
    }
  
    openShareModal() {
      this.setState({ isShareModalOpen: true })
    }
  
    closeShareModal() {
      this.setState({ isShareModalOpen: false })
    }
  
    render () {
      
    return (
      <React.Fragment>
        <SplitPane split="horizontal" allowResize={false} sizes={this.state.gsplit} resizerSize={5} onChange={(data) => this.setState({gsplit: data})}>
          <div className="ag-theme-balham" style={{height: '100%'}}>
            <AgGridReact 
              columnDefs={this.state.columnDefs}
              defaultColDef={defaultColDef}
              autoGroupColumnDef={autoGroupColumnDef}
              sideBar={defaultSideBar}
              suppressContextMenu={true}
              animateRows={true}
              rowMultiSelectWithClick={false}
              rowSelection={rowSelection}
              rowGroupPanelShow={rowGroupPanelShow}
              onGridReady={this.onGridReady}
              onGridRefresh={this.onGridRefresh}
              onSelectionChanged={this.onSelectionChanged}
              onRowDataUpdated={this.onRowDataUpdated}
              onModelUpdated={this.onModelUpdated}
              onFirstDataRendered={this.onFirstDataRendered}
              onStoreRefreshed={this.onStoreRefreshed}
              rowModelType={rowModelType}
              getChildCount={this.getChildCount}
              cacheBlockSize={cacheBlockSize}
              alwaysShowHorizontalScroll={false}
              alwaysShowVerticalScroll={false}
            ></AgGridReact>
          </div>
        {this.state.cards ? (
        <div className={`h-100 overflow-auto d-flex flex-wrap`} onScroll={this.handleCardsScroll}>
          <div className={`w-100 justify-content-center ${((this.state.updatingRowData) || (this.state.cards.length > 0)) ? 'd-none' : 'd-flex'}`}>
            <p style={{fontFamily: "var(--font-family-sans-serif)"}}>
                There's nothing here, yet.
            </p>
          </div>
          {this.state.cards.map((row, index) => { this.tableRefs[index] = React.createRef(); return (
          <Col xs={2} className='m-1 mb-4' style={{width: "calc(15% + 23px)"}}>
            <Card 
              id={`ucg-${row.id}`}
              key={index} 
              onClick={() => this.cardSelectionChanged(index)}
              className={`m-1 p-0 text-primary border border-2 ${(index === this.state.selectedCard) ? 'border-primary shadow-lg' : 'shadow'}`} 
            >
              <Card.Header className={`p-1 fs-6 text-white bg-primary`}>
                {row.name}&nbsp;[{row.id}]
              </Card.Header>
              <Card.Body className='p-2 d-flex align-content-end flex-column'>
                <Card.Text style={{overflow: "auto", maxHeight: "200px"}}>
                  <table ref={this.tableRefs[index]} className="w-100">
                  {JSON.parse(row.categories).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((c, index) => (
                    <tr>
                      <td key={`uc-${index}`} className="w-100 mb-1 p-1 border rounded text-start" style={{backgroundColor: `${pastelBackgrounds[index % 9]}`, opacity: "1"}}>
                        {c.name}
                      </td>
                    </tr>
                  ))}
                  </table>
                </Card.Text>
                <Card.Text className="d-flex">
                  <Button data-id={index} title="Edit" className="me-1 border border-gray" style={{ borderRadius: "50%" }} variant="outline-primary" onClick={this.editUCG}><i className="bi bi-pencil-square"></i></Button>
                  {(row.ownerid === row.userid) ? (
                  <Button data-id={index} title="Share" className="me-1 border border-gray" style={{ borderRadius: "50%" }} variant="outline-primary" onClick={this.openShareModal}><i className="bi bi-share"></i></Button>
                  ) : ''}
                  <Button data-id={index} title="Copy to Clipboard" className="me-1 border border-gray" style={{ borderRadius: "50%" }} variant="outline-primary" onClick={this.copyTable}><i className="bi bi-copy"></i></Button>
                  {(row.ownerid === row.userid) ? (
                  <Button data-id={index} title="Delete" className="ms-auto border border-gray" style={{ borderRadius: "50%" }} variant="outline-primary" onClick={this.deleteUCG}><i className="bi bi-trash"></i></Button>
                  ) : (
                  <Button data-id={index} title={`Shared by ${row.u_name_email}`} className="ms-auto border-0" style={{borderRadius: "50%"}} variant="outline-primary"><i className="bi bi-people-fill"></i></Button>
                  )}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          )})}
        </div>
          ) : <></>}
      </SplitPane>
      <MultiSelectCategories 
        isOpen={this.state.isCategoryModalOpen} 
        isNew={this.state.isCategoryModalNew}
        onClose={this.closeCategoryModal} 
        ucgId={this.state.selectedId} 
        ucgData={this.state.selectedData}
        loggedInUserId={this.props.uid} 
        pfr={this.props.pfr} 
      />
      <ShareObject isOpen={this.state.isShareModalOpen} onClose={this.closeShareModal} objType="ucg" objId={this.state.selectedId} loggedInUserId={this.props.uid} />
      </React.Fragment>
    )}
}

export default UserCategories;