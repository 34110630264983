import React, {useState, useRef, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios';

import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'

function MultiSelectCategories(props) {
    const catGridRef = useRef();
    const relGridRef = useRef();
    const selGridRef = useRef();
    const [categoryCount, setCategoryCount] = useState(0);
    const [ucgName, setUcgName] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState(0);
    const [selectedCategoryName, setSelectedCategoryName] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [relatedRows, setRelatedRows] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState([]);
    const [isValidData, setIsValidData] = useState(false);

    const getCategoryRows = (params) => {
        const serverApiUrl = `/api/getagcategories`;

        fetch(serverApiUrl, {
            method: 'post',
            body: JSON.stringify(params.request),
            headers: { "Content-Type": "application/json; charset=utf-8" }
        })
        .then(httpResponse => httpResponse.json())
        .then(response => {
            params.successCallback(response.rows, response.lastRow);
            setCategoryCount(response.totals.Totals);
        })
        .catch(error => {
            console.error(error);
            params.failCallback();
        });
    };

    const getRelatedRows = (params) => {
        console.log(selectedCategoryId)
        const serverApiUrl = `/api/getagrelatedcategories/${selectedCategoryId}`;
        
        fetch(serverApiUrl, {
            method: 'post',
            body: JSON.stringify(params.request),
            headers: { "Content-Type": "application/json; charset=utf-8" }
        })
        .then(httpResponse => httpResponse.json())
        .then(response => {
            params.successCallback(response.rows, response.lastRow);
            setRelatedRows(response.rows);
        })
        .catch(error => {
            console.error(error);
            params.failCallback();
        });
    };

    useEffect(() => {
        if (props.isOpen) {
            setSelectedCategoryId(0);
            setSelectedCategoryName('');
            if (props.isNew) {
                setUcgName('');
                setSelectedRowData([]);
            } else {
                if (props.ucgData) {
                    setUcgName(props.ucgData.name);
                    setSelectedRowData(JSON.parse(props.ucgData.categories));
                }
            }
        }
    }, [props.isOpen])

    useEffect(() => {
        setIsValidData(((ucgName) && (ucgName !== '') && (ucgName.length < 255)) && (selectedRowData && (selectedRowData.length > 0)))
    }, [ucgName, selectedRowData])

    useEffect(() => {
        relGridRef.current?.api?.setServerSideDatasource({getRows: getRelatedRows});
    }, [selectedCategoryId]);

    const onCatGridReady = (params) => {
        params.api.setServerSideDatasource({getRows: getCategoryRows});
    };

    const onRelGridReady = (params) => {
        params.api.setServerSideDatasource({getRows: getRelatedRows});
    };

    const onCatSelectionChanged = (event) => {
        const selRows = event.api?.getSelectedRows()?.filter(row => row.id);
        setSelectedRows(selRows);
        setSelectedCategoryId((selRows.length > 0) ? selRows[0].id : 0);
        setSelectedCategoryName((selRows.length > 0) ? selRows[0].name : '');
    };

    const onRelSelectionChanged = (event) => {
        const selRows = event.api?.getSelectedRows()?.filter(row => row.id);
        setSelectedRows(selRows);
    };

    const onSelGridSelectionChanged = (event) => {
        const selRows = event.api?.getSelectedRows();
        setSelectedRows(selRows);
    };

    const handleAddAllCategoriesClick = () => {
        const filterModel = catGridRef.current.api?.getFilterModel();
        const serverApiUrl = `/api/getagcategories`;
        const request = {
            "startRow": 0,
            "endRow": -1,
            "rowGroupCols": [],
            "valueCols": [],
            "pivotCols": [],
            "pivotMode": false,
            "groupKeys": [],
            "filterModel": filterModel,
            "sortModel": []
        };

        fetch(serverApiUrl, {
            method: 'post',
            body: JSON.stringify(request),
            headers: { "Content-Type": "application/json; charset=utf-8" }
        })
        .then(httpResponse => httpResponse.json())
        .then(response => {
            setSelectedRows(response.rows);

            const filteredRows = response.rows.filter(row => (!selectedRowData.map(o => o.id).includes(row.id)))
            setSelectedRowData(prevselectedRowData => [...prevselectedRowData, ...filteredRows]);
        })
        .catch(error => {
            console.error(error);
        });
    }

    const handleAddAllRelatedClick = () => {
        if (relatedRows.length > 0) {
            const filteredRows = relatedRows.filter(row => (!selectedRowData.map(o => o.id).includes(row.id)))
            setSelectedRowData(prevselectedRowData => [...prevselectedRowData, ...filteredRows]);
        }
    }

    const handleAddClick = () => {
        if (selectedRows && (selectedRows.length > 0)) {
            const filteredRows = selectedRows.filter(row => (!selectedRowData.map(o => o.id).includes(row.id)))
            setSelectedRowData(prevselectedRowData => [...prevselectedRowData, ...filteredRows]);
        }
    }

    const handleRemoveClick = () => {
        if (selectedRows && (selectedRows.length > 0)) {
            const filteredRows = selectedRowData.filter(row => !selectedRows.includes(row));
            setSelectedRowData(filteredRows);
        }
    }

    const handleRemoveAllClick = () => {
        setSelectedRowData([]);
    }

    const hideCategoryModal = () => {
        setUcgName('');
        setSelectedRowData([]);
        setSelectedRows([]);
        props.onClose();
    }

    const saveUserCategories = async() => {
        var request;

        if (props.isNew) {
            request = {
                url: '/addUserGroup',
                method: 'POST',
                data: {userId: props.loggedInUserId, ucgName: ucgName, selectedCategories: selectedRowData},
            }
        } else {
            request = {
                url: '/updateUserGroup',
                method: 'POST',
                data: {userId: props.loggedInUserId, ucgId: props.ucgId, ucgName: ucgName, selectedCategories: selectedRowData},
            }
        }

        axios(request)
        .then((res) => {
            console.log(res);
        })
        .catch((err) => {
            console.log(err)
        })

        props.pfr();
        hideCategoryModal();
    }

    const onUcgNameChange = (e) => {
        setUcgName(e.currentTarget.value);
    }

    const catColumns = [
        { field: 'id', headerName: 'Id', hide: true, filter: 'agNumberColumnFilter', width: 50, headerClass: 'custom-header-class' },
        { field: 'cgid', headerName: 'Category Type Id', hide: true, filter: 'agNumberColumnFilter', width: 50, headerClass: 'custom-header-class' },
        { field: 'groupname', headerName: 'Category Type', sort: 'asc', filter: 'agTextColumnFilter', width: 300, headerClass: 'custom-header-class' },
        { field: 'name', headerName: 'Category Name', filter: 'agTextColumnFilter', sort: 'asc', width: 500, headerClass: 'custom-header-class' },
    ];

    const relColumns = [
        { field: 'id', headerName: 'Id', hide: true, filter: 'agNumberColumnFilter', width: 50, headerClass: 'custom-header-class' },
        { field: 'cgid', headerName: 'Category Type Id', hide: true, filter: 'agNumberColumnFilter', width: 50, headerClass: 'custom-header-class' },
        { field: 'groupname', headerName: 'Category Type', sort: 'asc', filter: 'agTextColumnFilter', width: 300, headerClass: 'custom-header-class' },
        { field: 'name', headerName: 'Category Name', filter: 'agTextColumnFilter', sort: 'asc', width: 500, headerClass: 'custom-header-class' },
    ];

    const selColumns = [
        { field: 'id', headerName: 'Id', hide: true, filter: 'agNumberColumnFilter', width: 50, headerClass: 'custom-header-class' },
        { field: 'cgid', headerName: 'Category Type Id', hide: true, filter: 'agNumberColumnFilter', width: 50, headerClass: 'custom-header-class' },
        { field: 'groupname', headerName: 'Category Type', hide: true, filter: 'agTextColumnFilter', width: 300, headerClass: 'custom-header-class' },
        { field: 'name', headerName: 'Selected Categories', filter: 'agTextColumnFilter', flex: 1, headerClass: 'custom-header-class' },
    ];

    const rowGroupPanelShow = 'onlyWhenGrouping';

    const defaultColDef = {
        sortable: true,
        resizable: true,
        enableValue: true,
        enableRowGroup: true,
        enablePivot: false,
        suppressMenu: false,
        filter: true,
        floatingFilter: true,
        filterParams: {
          buttons: ["reset"],
          closeOnApply: true,
        },
    };
      
    const catGridOptions = {
        rowModelType: 'serverSide',
        enableRangeSelection: false,
        rowSelection: 'multiple',
        columnHoverHighlight: false,
        suppressRowGroupHidesColumns: false,
        cacheBlockSize: 50,
    }
      
    const relGridOptions = {
        rowModelType: 'serverSide',
        enableRangeSelection: false,
        rowSelection: 'multiple',
        columnHoverHighlight: false,
        suppressRowGroupHidesColumns: false,
        cacheBlockSize: 50,
    }

    const statusBar = {
        statusPanels: [
          {
            statusPanel: 'agTotalAndFilteredRowCountComponent',
            align: 'left',
          },
        ],
      };

  return (
    <React.Fragment>
        <Modal show={props.isOpen} onHide={hideCategoryModal} size='xl' className='shadow-lg' backdrop="static" keyboard={false} >
          <Modal.Header className="py-2 bg-primary text-white">
            <span className="fs-5">{props.isNew ? "New" : "Edit"} User Category Group</span>
          </Modal.Header>
          <Modal.Body className="m-1 p-1">
            <div id="ucg-container" className="m-0 px-2 d-flex flex-column" style={{height: "600px"}}>
                <InputGroup size="sm" className="mb-1">
                    <InputGroup.Text id="lbl-ucg-name" className='fw-bold' style={{width: "auto"}}>Group Name</InputGroup.Text>
                    <Form.Control 
                            id = "ctl-ucg-name"
                            className='rounded' 
                            size="sm" 
                            type="text" 
                            autoFocus
                            placeholder="Enter a name for your group..." 
                            value={ucgName}
                            onChange={onUcgNameChange}
                    />
                </InputGroup>
                <div className="m-0 p-0 h-100 d-flex flex-row">
                    <Col>
                        <Row className="m-0 mb-1" style={{height: "4%"}}><Col className="w-100 p-0 bg-light text-end"><span className="px-2">{categoryCount}</span></Col><Col className="p-1 mx-1" xs={1}></Col></Row>
                        <Row className="m-0" style={{height: "46%"}} >
                            <Col className="p-0 h-100 ag-theme-balham">
                                <AgGridReact
                                    id="cat-grid"
                                    ref={catGridRef}
                                    gridOptions={catGridOptions}
                                    defaultColDef={defaultColDef}
                                    columnDefs={catColumns}
                                    onGridReady={onCatGridReady}
                                    onSelectionChanged={onCatSelectionChanged}
                                    onRowDoubleClicked={handleAddClick}
                                    suppressContextMenu={true}
                                    rowGroupPanelShow={rowGroupPanelShow}
                                    animateRows={true}
                                    // statusBar={statusBar}
                                />
                            </Col>
                            <Col className="p-1 mx-1 h-100 d-flex flex-column justify-content-center" xs={1}>
                                <Button title="Add All" className="m-1" size="sm" onClick={handleAddAllCategoriesClick}><i class="bi bi-chevron-double-right"></i></Button>
                                <Button title="Add Selected" className="m-1" size="sm" onClick={handleAddClick}><i class="bi bi-chevron-right"></i></Button>
                                <Button title="Clear Selected" className="m-1" size="sm" onClick={handleRemoveClick}><i class="bi bi-chevron-left"></i></Button>
                                <Button title="Clear all" className="m-1" size="sm" onClick={handleRemoveAllClick}><i class="bi bi-chevron-double-left"></i></Button>
                            </Col>
                        </Row>
                        <Row className="m-0" style={{height: "8%"}}>
                            <Col className="my-auto p-0 fs-6 fw-bold">
                                <span>{`Related Categories ${(selectedCategoryId > 0) ? `for ${selectedCategoryName}` : ''}`}</span>
                            </Col>
                        </Row>
                        <Row className="m-0" style={{height: "42%"}}>
                            <Col className="p-0 h-100 ag-theme-balham">
                                <AgGridReact
                                    id="rel-grid"
                                    ref={relGridRef}
                                    gridOptions={relGridOptions}
                                    defaultColDef={defaultColDef}
                                    columnDefs={relColumns}
                                    onGridReady={onRelGridReady}
                                    onSelectionChanged={onRelSelectionChanged}
                                    onRowDoubleClicked={handleAddClick}
                                    suppressContextMenu={true}
                                    rowGroupPanelShow={rowGroupPanelShow}
                                    animateRows={true}
                                />
                            </Col>
                            <Col className="p-1 mx-1 h-100 d-flex flex-column justify-content-center" xs={1}>
                                <Button title="Add All" className="m-1" size="sm" onClick={handleAddAllRelatedClick} disabled={relatedRows.length <= 0}><i class="bi bi-chevron-double-right"></i></Button>
                                <Button title="Add Selected" className="m-1" size="sm" onClick={handleAddClick} disabled={relatedRows.length <= 0}><i class="bi bi-chevron-right"></i></Button>
                                <Button title="Clear Selected" className="m-1" size="sm" onClick={handleRemoveClick} disabled={relatedRows.length <= 0}><i class="bi bi-chevron-left"></i></Button>
                                <Button title="Clear All" className="m-1" size="sm" onClick={handleRemoveAllClick} disabled={relatedRows.length <= 0}><i class="bi bi-chevron-double-left"></i></Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={4}>
                        <Row className="m-0 mb-1" style={{height: "4%"}}>
                            <Col className="w-100 p-0 bg-light text-end">
                                <span className="px-2">{(selectedRowData.length > 0) ? selectedRowData.length : ''}</span>
                            </Col>
                        </Row>
                        <Row className="ag-theme-balham" style={{height: "96%"}}>
                            <AgGridReact
                                id="sel-grid"
                                ref={selGridRef}
                                rowData={selectedRowData}
                                defaultColDef={defaultColDef}
                                columnDefs={selColumns}
                                rowSelection={"multiple"}
                                onSelectionChanged={onSelGridSelectionChanged}
                                onRowDoubleClicked={handleRemoveClick}
                                suppressContextMenu={true}
                            />
                        </Row>
                    </Col>
                </div>
                <div className='mt-2 py-1 d-flex justify-content-end'>
                    <Button title="Cancel" className='mx-1' variant="secondary" onClick={hideCategoryModal}>
                        Cancel
                    </Button>
                    <Button title="Save" className={`mx-1`} variant="primary" onClick={saveUserCategories} disabled={!isValidData}>
                        Save
                    </Button>
                </div>
            </div>
          </Modal.Body>
        </Modal>
    </React.Fragment>
  )
}

export default MultiSelectCategories;