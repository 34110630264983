import { useEffect, useState } from 'react';
import React from "react"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios'
import { Nav, Tab, Form, Button } from 'react-bootstrap';

function CForm(props) {


    const [key, setKey] = useState('Template Details');
    const [validated, setValidated] = useState(false);

    const [formdata, setformdata] = useState(
        {
            templatename: '',
            description: '',
            firstname: '',
            lastname: '',
            phone: '',
            email: '',
            company: '',
            website: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            subject: '',
            message200: '',
            message400: '',
            message1000: '',
            title: '',
            besttimetorespond: '',
            date:  new Date(),
            unidentified: ''
        }
    )

    const formTabs = [
        { name: 'Template Details', fields:[ 'templatename', 'description' ]},
        { name: 'Personal', fields: [ 'firstname', 'lastname', 'phone', 'email','address', 'city', 'state', 'zip', 'country'] },
        { name: 'Professional', fields: ['company', 'website', 'title', 'besttimetorespond'] },
        { name: 'Message', fields: ['subject', 'message200', 'message400', 'message1000'] },
        { name: 'Submit', fields: ['date', 'unidentified'] }
    ]
    const fieldnames = formTabs.reduce((fields, tab) => { return [...fields, ...tab.fields] }, [])
    const placeholders = {
        templatename: 'Name for this Template',
        description: 'More information about this Template',
        firstname: 'First Name',
        lastname: 'Last Name',
        phone: 'Phone number',
        email: 'E-mail id',
        company: 'Company Name',
        website: 'Company Website',
        address: 'Local Address',
        city: 'City',
        state: 'State',
        zip: 'Postal Code',
        country: 'Country',
        subject: 'Subject of your Message',
        message200: 'A short message of less than 200 characters',
        message400: 'A short message of less than 400 characters',
        message1000: 'A long message of less than 1000 characters',
        messagenolimit: 'A long message of more than 1000 characters',
        title: 'Your Job Title',
        besttimetorespond: 'Best time to contact you',
        date: 'Default Date value in DD/MM/YYYY format',
        unidentified: 'Default value for unidentified fields'
    }

    const createFormTemplate = () => {

        const request = {
            url: '/createformtemplate',
            data: { userid: props.uid, formdata: formdata },
            method: "POST"
        }

        if (window.confirm(`Create Template ${formdata.templatename}?`)) {
            axios(request).then(() => { alert('Template Created') })
                .catch((err) => { alert('Server Error! Template not created') })
            props.disableCForm()
        }

    }

    const modifyFormTemplate = () => {

        const request = {
            url: '/modifyformtemplate',
            data: { tid: props.tid, formdata: formdata },
            method: "POST"
        }

        if (window.confirm(`Modify template ${formdata.templatename} with given details?`)) {
            axios(request).then(() => { alert('Template Modified') })
                .catch((err) => { alert('Server Error! Template details NOT modified') })
            props.disableCForm()
        }

    }

    const switchToTab = (key) => {
        setKey(key)
    }

    const handlefieldvalue = (field, value) => {
        setformdata(prevdata => ({
            ...prevdata,
            [field]: value
        }));
    }

    const validateFields = (form, fields) => {
        return fields.every(fieldName => form.elements[fieldName].checkValidity());
    };

    const findInvalidField = (form, fields) => {
        return fields.find(fieldName => form.elements[fieldName].checkValidity() == false)
    }
  
    const isFormTouched = (form, fields) => {
        return fields.some(fieldName => form.elements[fieldName].checkValidity());
    }

    const handleCancel = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget
        if (!isFormTouched(form, fieldnames)) {
            props.disableCForm()
        } else {
            if (window.confirm(`You have not saved the form. Discard data?`)) {
                props.disableCForm()
            }
        }

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        let invalidTab = null;
        let fields = []
        let  firstInvalid = null
        for (const tab of formTabs) {
            if (!validateFields(form, tab.fields)) {
                invalidTab = tab.name;
                fields = tab.fields
                break;
            }
        }

        if (invalidTab) {
            switchToTab(invalidTab);  
            firstInvalid = document.getElementsByName(findInvalidField(form, fields))[0]
            setTimeout(() => {
                firstInvalid.focus();
              }, 0);
        } else {
            props.modifymode ? modifyFormTemplate() : createFormTemplate();
        }

        setValidated(true);
    };

    useEffect(() => {
        setformdata(props.formdata) 
    }, [props.formdata])

    useEffect(()=>{
        const date = new Date().toISOString().slice(0,10);;
        setformdata(prevState => ({
            ...prevState,
            'date': date
        }))
    },[])

    return (
        <>
            <Container fluid>
                <Form noValidate validated={validated} onSubmit={handleSubmit} onReset={handleCancel}>
                    <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                        <Nav variant="underline">
                            <Nav.Link eventKey="Template Details">Template Details</Nav.Link>
                            <Nav.Link eventKey="Personal">Personal</Nav.Link>
                            <Nav.Link eventKey="Professional">Professional</Nav.Link>
                            <Nav.Link eventKey="Message">Message</Nav.Link>
                            <Nav.Link eventKey="Submit">Defaults</Nav.Link>
                        </Nav>

                        <Tab.Content className="mt-3" style={{ height: "480px" }}>
                            <Tab.Pane eventKey="Template Details">
                                <Row>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Template Name</Form.Label>
                                        <Form.Control required name='templatename' placeholder={placeholders.templatename} value={formdata.templatename} onChange={(e) => handlefieldvalue('templatename', e.target.value)} type="text" />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Campaign Description</Form.Label>
                                        <Form.Control required name='description' as="textarea" rows={2} placeholder={placeholders.description} value={formdata.description} onChange={(e) => handlefieldvalue('description', e.target.value)} />
                                    </Form.Group>
                                </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="Personal">
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control required name='firstname' placeholder={placeholders.firstname} value={formdata.firstname} onChange={(e) => handlefieldvalue('firstname', e.target.value)} type="text" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control required name='lastname' placeholder={placeholders.lastname} value={formdata.lastname} onChange={(e) => handlefieldvalue('lastname', e.target.value)} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control pattern="^(\+?\d{1,3}[\s.-]?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}(\s*\s*\d{1,5})?$" required name='phone' placeholder={placeholders.phone} value={formdata.phone} onChange={(e) => handlefieldvalue('phone', e.target.value)} type="tel" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control required name='email' placeholder={placeholders.email} value={formdata.email} onChange={(e) => handlefieldvalue('email', e.target.value)} type="email" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            {/* </Tab.Pane>
                            <Tab.Pane eventKey="Address"> */}
                                <Row>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control required name='address' placeholder={placeholders.address} value={formdata.address} onChange={(e) => handlefieldvalue('address', e.target.value)} type="text" />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>City</Form.Label>
                                            <Form.Control required name='city' placeholder={placeholders.city} value={formdata.city} onChange={(e) => handlefieldvalue('city', e.target.value)} type="text" />
                                        </Form.Group>
                                    </Col><Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>State</Form.Label>
                                            <Form.Control required name='state' placeholder={placeholders.state} value={formdata.state} onChange={(e) => handlefieldvalue('state', e.target.value)} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Zip Code</Form.Label>
                                            <Form.Control pattern="^\d{4,6}$" required name='zip' placeholder={placeholders.zip} value={formdata.zip} onChange={(e) => handlefieldvalue('zip', e.target.value)} type="text" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Country</Form.Label>
                                            <Form.Control required name='country' placeholder={placeholders.country} value={formdata.country} onChange={(e) => handlefieldvalue('country', e.target.value)} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Professional">
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Company</Form.Label>
                                            <Form.Control required name='company' placeholder={placeholders.company} value={formdata.company} onChange={(e) => handlefieldvalue('company', e.target.value)} type="text" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Website</Form.Label>
                                            <Form.Control required name='website' placeholder={placeholders.website} value={formdata.website} onChange={(e) => handlefieldvalue('website', e.target.value)} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Role Title</Form.Label>
                                            <Form.Control required name='title' placeholder={placeholders.title} value={formdata.title} onChange={(e) => handlefieldvalue('title', e.target.value)} type="text" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Best Time To Respond</Form.Label>
                                            <Form.Control required name='besttimetorespond' placeholder={placeholders.besttimetorespond} value={formdata.besttimetorespond} onChange={(e) => handlefieldvalue('besttimetorespond', e.target.value)} type="text" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Message">
                                <Row>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Subject</Form.Label>
                                        <Form.Control required name='subject' placeholder={placeholders.subject} value={formdata.subject} onChange={(e) => handlefieldvalue('subject', e.target.value)} type="text" />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Short Message ({"<"} 200 characters)</Form.Label>
                                        <Form.Control required name='message200' as="textarea" rows={3} maxLength='200' placeholder={placeholders.message200} value={formdata.message200} onChange={(e) => handlefieldvalue('message200', e.target.value)} />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Message ({"<"} 400 characters)</Form.Label>
                                        <Form.Control required name='message400' as="textarea" rows={3} maxLength='400' placeholder={placeholders.message400} value={formdata.message400} onChange={(e) => handlefieldvalue('message400', e.target.value)} />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Long Message (upto 1000 characters)</Form.Label>
                                        <Form.Control required name='message1000' as="textarea" rows={3} maxLength='1000' placeholder={placeholders.message1000} value={formdata.message1000} onChange={(e) => handlefieldvalue('message1000', e.target.value)} />
                                    </Form.Group>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Submit">
                                <Row>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control required name='date' value={formdata.date} onChange={(e) => handlefieldvalue('date', e.target.value)} type="date" />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Unidentified</Form.Label>
                                        <Form.Control required name='unidentified' placeholder={placeholders.unidentified} value={formdata.unidentified} onChange={(e) => handlefieldvalue('unidentified', e.target.value)} type="text" />
                                    </Form.Group>
                                </Row>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                    <div className='p-2 d-flex justify-content-end'>
                        <Button type="reset" className='mx-1' variant="secondary"  >
                            Cancel
                        </Button>
                        <Button type="submit" className={`mx-1`} title={props.modifymode ? 'Modify this Template' : 'Create new Template'} variant="primary" >
                            Save
                        </Button>
                    </div>
                </Form>
            </Container>
        </>
    )
}

export default CForm