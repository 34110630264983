import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function HgemsFilters(props) {
    const [category, setCategory] = useState('');
    const [country, setCountry] = useState('')
    const [clearAllVisible, setClearAllVisible] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false);

    useEffect(() => {
        if ((category.length > 0) || (country.length > 0)) {
            setClearAllVisible(true)
        } else {
            setClearAllVisible(false);
            if (filterApplied) {
                resetFilterModel();
            }
        }
    }, [category, country])

    function handleCategoryChange(event) {
        setCategory(event.target.value);
    };

    function handleCountryChange(event) {
        setCountry(event.target.value);
    };

    function resetFilterModel() {
        if (category !== '') setCategory('');
        if (country !== '') setCountry('');
        props.ppf([]);
        setFilterApplied(false);
    }

    function applyFilter() {
        var filterModel = {};
        if (category !== '') {
            filterModel.ucategory = {};
            filterModel.ucategory.filterType = "text";
            filterModel.ucategory.type = "contains";
            filterModel.ucategory.filter = category;
        };
        if (country !== '') {
            filterModel.country = {};
            filterModel.country.filterType = "text";
            filterModel.country.type = "contains";
            filterModel.country.filter = country;
        };
        props.ppf(filterModel);
        setFilterApplied(true);
    }

    function handleSubmitFilters(event) {
        event.preventDefault();
        event.stopPropagation();       
    }

    return (
        <React.Fragment>
            <div className="p-2 border border-gray shadow-sm fs-6">
                <div className={`w-100 justify-content-end d-flex ${clearAllVisible ? 'd-flex' : 'd-none'}`} style={{color: "#ff3f6c", fontWeight: "700", fontSize: "13px", cursor: "pointer"}} onClick={resetFilterModel}>CLEAR ALL</div>
                <Form id="frmHiddenGems" onSubmit={handleSubmitFilters}>
                    <p>
                        <Form.Label id="lblCategory" for="ctlCategory">Category</Form.Label>
                        <Form.Control id="ctlCategory" className="border border-primary" type="search" autoComplete='off' value={category} onChange={handleCategoryChange}></Form.Control>
                    </p>
                    <p>
                        <Form.Label id="lblCountry" for="ctlCountry">Country</Form.Label>
                        <Form.Control id="ctlCountry" className="border border-primary" type="search" autoComplete='off' value={country} onChange={handleCountryChange}></Form.Control>
                    </p>
                    <div className="d-flex">
                        <Button id="btnApply" type="submit" className="ms-auto" size="sm" disabled={!clearAllVisible} onClick={applyFilter}>Apply</Button>
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default HgemsFilters;