import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import SplitPane from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';

import HiddenGems from './hiddengems';
import HgemsFilters from './hfilters';

function Hmain(props) {
  const SIDE_MIN_WIDTH = '40px';
  const SIDE_MAX_WIDTH = '240px';

  const hfiltersRef = useRef(null);
  const hiddenGemsRef = useRef(null);

  const [sbsplit, setSbsplit] = useState([SIDE_MAX_WIDTH, 'auto']);
  const [hsplit, setHsplit] = useState(["100%", 'auto']);
  const thisResizerSize = 5;

  const [thisProps] = useState(props);

  const [showSidebar, setShowSidebar] = useState(true);
  const toggleSidebar = () => { 
    setShowSidebar(!showSidebar); 
    setSbsplit(showSidebar ? [SIDE_MIN_WIDTH, 'auto'] : [SIDE_MAX_WIDTH, 'auto']);
  };

  const [showGridView, setShowGridView] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);

  const [updatingHiddenGemsData, setUpdatingHiddenGemsData] = useState(false);
  const passUpdatingHiddenGemsData = (data) => (setUpdatingHiddenGemsData(data));

  const [hiddenGemsData, setHiddenGemsData] = useState([]);
  const updateHiddenGemsData = (data) => { setHiddenGemsData(data) };

  const [hiddenGemsFilter, setHiddenGemsFilter] = useState({});
  const passHiddenGemsFilter = (data) => { setHiddenGemsFilter(data) };

  const toggleView = () => {
    setShowGridView(!showGridView)
  }

  const tabRefresh = () => {
    setForceRefresh(!forceRefresh)
  }

  useEffect(() => {
    hiddenGemsRef.current.toggleView(!showGridView);
  }, [showGridView]);

  useEffect(() => {
    hiddenGemsRef.current.fetchData()
  }, [forceRefresh]);

  useEffect(() => {
    hiddenGemsRef.current.applyFilter(hiddenGemsFilter);
    hiddenGemsRef.current.fetchData();
  }, [hiddenGemsFilter]);

  return (
    <React.Fragment>
      <SplitPane allowResize={false} sizes={sbsplit} onChange={setSbsplit}>
        <div className='w-100 border shadow'>
          <div className='w-100 p-2 d-flex align-items-center bg-primary text-white shadow' style={{ height: '40px' }}>
            <span className={`w-100 fs-5 fw-bold`}>{`${showSidebar ? 'Filter' : ''}`}</span>
            <Button size="lg" className={`m-0 p-0 border border-primary`} onClick={toggleSidebar}>
              <i className={`bi ${showSidebar ? 'bi-caret-left-fill' : 'bi-caret-right-fill'}`}></i>
            </Button>
          </div>
          <div className={`w-100 p-2 side-panel overflow-auto ${showSidebar ? 'visible' : 'invisible'}`}>
            <HgemsFilters ref={hfiltersRef} ppf={passHiddenGemsFilter} />
          </div>
        </div>
        <div className='w-100 h-100 d-flex flex-column overflow-hidden'>
          <Tab.Container defaultActiveKey="hiddengems">
            <Nav variant='tabs' className='fs-6 border' >
              <Nav.Item><Nav.Link eventKey="hiddengems">Hidden Gems{updatingHiddenGemsData ? (<React.Fragment>&nbsp;<i className="fa fa-spinner fa-pulse text-white"></i></React.Fragment>) : ''}</Nav.Link></Nav.Item>
              <Nav.Item className='ms-auto'>
                <Button title="Grid / Cards" className={`btn btn-primary mx-1 float-right`} onClick={toggleView}><i className={`bi ${showGridView ? 'bi-list' : 'bi-grid'}`}></i></Button>
                <Button title="Refresh" className={`btn btn-primary mx-1 float-right`} onClick={tabRefresh}><i className={`bi bi-arrow-repeat`}></i></Button>
              </Nav.Item>
            </Nav>
            <Tab.Content className='w-100' style={{ height: "calc(100% - 34px)" }} animation="true" mountOnEnter={true} unmountOnExit={true} >
              <Tab.Pane className='w-100 h-100' eventKey="hiddengems" title="Hidden Gems">
                <SplitPane split="horizontal" sizes={hsplit} resizerSize={thisResizerSize} onChange={setHsplit}>
                  <Card className={`w-100 h-100 overflow-auto`}>
                    <HiddenGems ref={hiddenGemsRef} data={updateHiddenGemsData} upd={passUpdatingHiddenGemsData} uid={thisProps.uid} />
                  </Card>
                  <Card />
                </SplitPane>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </SplitPane>
    </React.Fragment >
  )
}

export default Hmain;
