import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import "flag-icons/css/flag-icons.min.css";
import './leads.css';

import SplitPane from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';

const MSG_UNLOCK_LEADS = 'Please use credits to unmask the lead';

const defaultColDef = {
    filter: true,
    sortable: true,
    resizable: true,
    enableRowGroup: true
};

const autoGroupColumnDef = {
  pinned: 'left', // Pin the group column to the left
  width: 600,
  cellRenderer: 'agGroupCellRenderer', // Default renderer
  cellRendererParams: {
      suppressCount: true, // Show the count of rows in the group
      innerRenderer: (params) => {
        let customHeader = '';
        if (params.node.group) {
          // const firstChild = params.node.childrenAfterFilter && params.node.childrenAfterFilter[0];
          // const jobName = firstChild ? firstChild.data.jobname : '';
          customHeader = `${params.node.key} | #Gems: ${params.node.allChildrenCount}`
        }
        return (<span>{customHeader}</span>)
      }
    }
  }

const defaultSideBar = {
  toolPanels: [
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      toolPanelParams: {
        suppressExpandAll: true,
        suppressFilterSearch: true,
      }
    },
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'column',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressExpandAll: true,
        suppressFilterSearch: true,
      },
    },
  ],
};
const rowSelection = 'single';
const rowGroupPanelShow = 'onlyWhenGrouping';

const rowModelType = 'serverSide';
const cacheBlockSize = 100;

const pastelBackgrounds = ["#FFEBEE","#F5FFFA","#FFF0F5","#FFDAB9","#F0F8FF","#FFFACD","#FFE4E1","#F0FFF0","#FFF5EE","#E0FFFF"];

class HiddenGems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isGridView: true,
            gridApi: null,
            rowData: [],
            columnDefs: [],
            selectedData: [],
            selectedUtld: '',
            updatingRowData: false,
            gsplit: [`100%`, 'auto'],
            cards: [],
            selectedCard: 0,
            lastVisibleCard: 0,
            filterMode: 0,
            keywords: '',
        }

        this.onGridReady = this.onGridReady.bind(this);
        this.getRows = this.getRows.bind(this);
        this.getChildCount = this.getChildCount.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.onModelUpdated = this.onModelUpdated.bind(this);
        this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
        this.groupCountComparator = this.groupCountComparator.bind(this);
        this.onStoreRefreshed = this.onStoreRefreshed.bind(this);
        this.checkRenderer = this.checkRenderer.bind(this);
        this.statusRenderer = this.statusRenderer.bind(this);
        this.flagRenderer = this.flagRenderer.bind(this);
        this.tldRenderer = this.tldRenderer.bind(this);
        this.urlRenderer = this.urlRenderer.bind(this);
        this.toggleView = this.toggleView.bind(this);
        this.refreshCards = this.refreshCards.bind(this);
        this.cardSelectionChanged = this.cardSelectionChanged.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.handleChangeKeywords = this.handleChangeKeywords.bind(this);
        this.handleApplyKeywords = this.handleApplyKeywords.bind(this);
        this.handleSubmitKeywords = this.handleSubmitKeywords.bind(this);
        this.handleCardsScroll = this.handleCardsScroll.bind(this);
        // this.unlockClicked=this.unlockClicked.bind(this);
        // this.unlockLeads=this.unlockLeads.bind(this)
        // this.unlockAllClicked=this.unlockAllClicked.bind(this);
        // this.unlockAllLeads=this.unlockAllLeads.bind(this);
        this.agRequestRef = React.createRef();
        
    };
 
    handleCardsScroll(e) {
      const cardsList = e.currentTarget;
      const top = (cardsList.scrollTop === 0);
      const bottom = (Math.abs(cardsList.scrollHeight - (cardsList.scrollTop + cardsList.clientHeight)) <= 5);
      if (top) {
        // this.state.gridApi?.ensureIndexVisible(0, 'top')
      } else if (bottom) {
        const index = this.state.cards.length;
        this.state.gridApi?.ensureIndexVisible(index, 'bottom');
        const lastVisibleIndex = Math.max(0, index - 1);
        const lastVisibleCard = this.state.cards[lastVisibleIndex].id;
        this.setState({ lastVisibleCard: lastVisibleCard });
      } else {
        this.setState({ lastVisibleCard: 0 });
      }
    }

    checkRenderer (params) {
      return (<div className='d-flex justify-content-center'><i className={`${(params.value && params.value === 1) ? 'bi bi-file-check' : ''}`}></i></div>)
    };

    flagRenderer (params) {
      return ((params.value && params.value !== '') ? <div title={params.data ? params.data.country : ''} className={`fi fi-${params.value.toLowerCase()}`}></div> : '')
    }

    tldRenderer (params) {
      return ((params.value && params.value !== '') ? (
        (params.data ? params.data.isunlocked : params.isunlocked) ? (
          <a rel="noreferrer" target='_blank' href={`https://${params.value}`}>{params.value}</a>
      ) : (
        <p onClick={() => alert(MSG_UNLOCK_LEADS)}>{params.value}</p>
      )
      ) : '' )
    }

    urlRenderer (params) {
      return ((params.value && params.value !== '') ? (
        (params.data ? params.data.isunlocked : params.isunlocked) ? (
          <div className='d-flex justify-content-center mx-1'>
            <a rel="noreferrer" target='_blank' href={`${params.value}`}><i title={params.title} className={`${params.icon}`}></i></a>
          </div> 
        ) : (
          <div className='d-flex justify-content-center mx-1'>
            <i title={params.title} className={`${params.icon}`} onClick={() => alert(MSG_UNLOCK_LEADS)}></i>
          </div> 
        ) 
        ) : '' )
    }

    statusRenderer (params) {
      return (<div className='mx-auto' style={{width: '18px', height:'18px'}}><i title={`Site Scan ${(params.value === 1) ? 'Completed' : 'Pending'}`} className={`bi ${(params.value === 1) ? 'bi-check-circle-fill' : 'bi-circle-half'} text-dark`}></i></div>)
    }

    fetchData = async() => {
      if (!this.state.updatingRowData) {
        this.setState({ updatingRowData: true });
        this.props.upd(true);

          // this.applyFilter([]);
          // this.state.gridApi?.setServerSideDatasource({getRows: this.getRows});
          this.state.gridApi?.refreshServerSide({purge: true});
          
          // var rowCount = this.state.gridApi?.getDisplayedRowCount();
      }
    }

    // Custom comparator function to sort groups by group counts
    groupCountComparator(valueA, valueB, nodeA, nodeB, isInverted) {
      // Get the group counts for the two groups
      const countA = nodeA ? nodeA.allChildrenCount : valueA; // Number of children in group A
      const countB = nodeB ? nodeB.allChildrenCount : valueB; // Number of children in group B

      // Compare the counts and return the result
      if (countA < countB) {
        return -1;
      } else if (countA > countB) {
        return 1;
      } else {
        return 0;
      }
    }

    onGridReady(params) {
        this.setState({
            gridApi: params.api,
        })
        params.api.setServerSideDatasource({getRows: this.getRows});
      }

    getRows(params) {
      const selKeywords = this.state.keywords;
      const selCategories = '';

      const serverURL = `/api/getaghiddengems/`;
      this.agRequestRef.current = params.request;
      
      this.setState({ updatingRowData: true });
      this.props.upd(true);
      fetch(serverURL, {
        method: 'post',
        body: JSON.stringify({...params.request, selKeywords, selCategories}),
        headers: { "Content-Type": "application/json; charset=utf-8" }
      })
      .then(httpResponse => httpResponse.json())
      .then(response => {
        params.successCallback(response.rows, response.lastRow);
        this.setState({ updatingRowData: false });
        this.props.upd(false);
      })
      .catch(error => {
        console.error(error);
        params.failCallback();
      })
    }

    getChildCount(data) {
      return data ? data.childCount : undefined
    }
    
    onSelectionChanged() {
      if (this.state.isGridView) {
        const data = this.state.gridApi.getSelectedRows();
        this.setState({
            selectedData: data,
            selectedUtld: data.utld
        })
        this.props.data(data);
      }
    }

    onModelUpdated = (params) => {
      this.setState({ updatingRowData: false });
      this.props.upd(false);
      this.refreshCards();
    }

    onStoreRefreshed = (params) => {
      this.setState({ updatingRowData: false });
      this.props.upd(false);
    }

    onFirstDataRendered = (params) => {
      var cols = [
        { field: 'jobid', headerName: 'List Id', filter: 'agNumberColumnFilter', width: 75, cellStyle: {textAlign: 'right'}, pinned: 'left', hide: true },
        { field: 'jobname', headerName: 'List Name', filter: 'agTextColumnFilter', pinned: 'left', sort: 'desc', comparator: this.groupCountComparator, rowGroup: true, hide: true },
        { field: 'leadsfound', headerName: '#Leads', filter: 'agNumberColumnFilter', width: 100, cellStyle: {textAlign: 'right'}, pinned: 'left', hide: true  },
        { field: 'ucategory', headerName: 'Category', filter: 'agTextColumnFilter', sort: 'desc', comparator: this.groupCountComparator, rowGroup: true },
        { field: 'domainid', headerName: 'Domain Id', filter: 'agNumberColumnFilter', width: 100, cellStyle: {textAlign: 'right'}, rowGroup: false, hide: true }, 
        { field: 'companyname', headerName: 'Company', filter: 'agTextColumnFilter' }, 
        { field: 'tld', headerName: 'Website', filter: 'agTextColumnFilter', cellRenderer: this.tldRenderer },
        { field: 'country', headerName: 'Country', filter: 'agTextColumnFilter', width: 100, cellStyle: {textAlign: 'center'}, cellRenderer: this.flagRenderer },
        { field: 'address', headerName: 'Address', filter: 'agTextColumnFilter', width: 500 }, 
      ];
      this.setState({columnDefs: cols})
    }

    toggleView(currentView) {
      this.setState({
        isGridView: currentView,
        gsplit: currentView ? ["100%", 'auto'] : ["0%", 'auto']
      })
      this.refreshCards();
    }

    refreshCards() {
      var cards = [];
      this.state.gridApi && this.state.gridApi.forEachNode((node, index) => {if ((!node.group) && (node.data)) cards.push(node.data)});
      if (cards.length > 0) {
        this.setState({cards: cards});
        this.props.data([]);
      }
      
      if (cards.length > 0) { 
        const selCard = Math.max(cards.findIndex(row => (row.utld === this.state.selectedUtld)), 0);
        const selData = cards[selCard];
        this.setState({
          selectedCard: selCard,
          selectedData: selData,
          selectedUtld: selData.utld
        });
        this.props.data([selData]);
      }

      setTimeout(() => {
        const card = document.getElementById(`lead-${this.state.lastVisibleCard}`);
        card?.scrollIntoView(false);
      }, 50)
    }

    
    cardSelectionChanged(rowid) {
      
      const data = this.state.cards[rowid];
      this.setState({
        selectedData: data,
        selectedUtld: data.utld,
        selectedCard: rowid,
        lastVisibleCard: rowid,
      })
      this.props.data([data]);
     
    }

    handleChangeKeywords(event) {
      this.setState({keywords: event.target.value});
    }

    applyFilter(filterValues) {
      this.state.gridApi && this.state.gridApi.setFilterModel(filterValues);
      if (!this.state.isGridView) this.refreshCards();
    }

    handleSubmitKeywords(event) {
      event.preventDefault(); 
      event.stopPropagation();
    }

    handleApplyKeywords(event) {
      this.fetchData();
    }

    render () {
      
    return (
      <React.Fragment>
      <div className="p-2 mb-1 border border-gray shadow-sm fs-6">
          <Form id="frmHiddenKeywords" onSubmit={this.handleSubmitKeywords}>
            <div className="d-flex flex-row">
              <Form.Control id="ctlKeywords" className="mx-2 border border-primary" autoComplete="off" type="search" placeholder='Search...' value={this.state.keywords} onChange={this.handleChangeKeywords}></Form.Control>
              <Button type="submit" className="me-2" style={{width: "48px"}} onClick={this.handleApplyKeywords}><i class="bi bi-search"></i></Button>
            </div>
          </Form>
      </div>
      <SplitPane split="horizontal" allowResize={false} sizes={this.state.gsplit} resizerSize={5} onChange={(data) => this.setState({gsplit: data})}>
          <div className="ag-theme-balham" style={{height: '100%'}}>
            <AgGridReact 
              columnDefs={this.state.columnDefs}
              defaultColDef={defaultColDef}
              autoGroupColumnDef={autoGroupColumnDef}
              sideBar={defaultSideBar}
              suppressContextMenu={true}
              suppressRowGroupHidesColumns={false}
              animateRows={true}
              rowMultiSelectWithClick={false}
              rowSelection={rowSelection}
              rowGroupPanelShow={rowGroupPanelShow}
              onGridReady={this.onGridReady}
              onGridRefresh={this.onGridRefresh}
              onSelectionChanged={this.onSelectionChanged}
              onRowDataUpdated={this.onRowDataUpdated}
              onModelUpdated={this.onModelUpdated}
              onFirstDataRendered={this.onFirstDataRendered}
              onStoreRefreshed={this.onStoreRefreshed}
              rowModelType={rowModelType}
              getChildCount={this.getChildCount}
              cacheBlockSize={cacheBlockSize}
              alwaysShowHorizontalScroll={false}
              alwaysShowVerticalScroll={false}
            ></AgGridReact>
          </div>
        {/*
        </div>
        */}
        {this.state.cards ? (
        <div className={`h-100 overflow-auto d-flex flex-wrap`} onScroll={this.handleCardsScroll}>
          <div className={`w-100 justify-content-center ${((this.state.updatingRowData) || (this.state.cards.length > 0)) ? 'd-none' : 'd-flex'}`}>
            <p style={{fontFamily: "var(--font-family-sans-serif)"}}>
                There's nothing here, yet.
            </p>
          </div>
          {this.state.cards.map((row, index) => (
          <Col xs={2} className='m-1 mb-4' style={{width: "calc(25% - 9px)"}}>
            <Card 
              id={`lead-${row.id}`}
              key={index} 
              onClick={() => this.cardSelectionChanged(index)}
              className={`m-1 p-0 text-primary border border-2 ${(index === this.state.selectedCard) ? 'border-primary shadow-lg' : 'shadow'}`} 
            >
              <Card.Body className='p-2 d-flex align-content-end flex-column'>
                <div id={`fi-${index}`} title={row.country} className={`me-auto mb-auto fi fi-${row.country?.toLowerCase()}`}></div>
                <Card.Text className='text-end' style={{textTransform: "capitalize"}}>
                  <span className="h6 ms-auto">{row.jobname.length <= 30 ? row.jobname : row.jobname.substring(0,30).concat('...')} [ID-{row.jobid}]</span>
                </Card.Text>
                <Card.Text className='p-1 mb-1 border rounded' style={{backgroundColor: `${pastelBackgrounds[row.domainid % 9]}`, opacity: "1"}}>
                  <span className="h6">
                  {row.tld ? (
                    row.isunlocked ? (
                    <a rel="noreferrer" target='_blank' href={`https://${row.tld}`}>{row.companyname}</a> 
                    ) : (
                    <u>{row.companyname}</u>
                    )
                  ) : (
                    <>{row.companyname}</>
                  )}
                  </span>
                  <br></br><span>{row.ucategory}</span>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
            ))}
        </div>
          ) : <div></div>}
      </SplitPane>
      </React.Fragment>
    )}
}

export default HiddenGems;